import { ACTIVE_RECIPE_KEY, SAVED_RECIPES_KEY } from "./constants";

export const setUrlQueryParams = (pathWithQuery = '/') => window.history.replaceState(null, '', pathWithQuery);

export const setSavedRecipesQueryParam = (savedRecipes) => {
  let idString = savedRecipes.join(',');
  const queryParamsObj = getQueryParamsObjFromUrl();
  if (idString.length > 0) {
    queryParamsObj[SAVED_RECIPES_KEY] = idString;
  } else {
    delete queryParamsObj[SAVED_RECIPES_KEY];
  }
  setQueryParamsObjectToUrl(queryParamsObj);
}

export const getQueryParamsObjFromUrl = () => {
  const queryString = window.location.search;
  const queryParamString = queryString.split('?')[1]; // remove leading '?'
  if (!queryParamString) return {};
  const queryParamsObj = {};
  queryParamString.split('&').forEach(paramString => {
    const keyValueArray = paramString.split('=');
    const key = keyValueArray[0];
    const value = keyValueArray[1];
    queryParamsObj[key] = value;
  });
  return queryParamsObj;
}

export const setQueryParamsObjectToUrl = (queryParamsObj) => {
  const keys = Object.keys(queryParamsObj);
  let pathWithQuery = '/';
  keys.forEach((key, idx) => {
    let paramString = idx === 0 ? '?' : '&';
    paramString += key;
    paramString += '=';
    paramString += queryParamsObj[key];
    pathWithQuery += paramString;
  });
  setUrlQueryParams(pathWithQuery);
}

export const getSavedRecipeIdsFromUrl = () => {
  const queryParams = getQueryParamsObjFromUrl();
  const savedRecipesString = queryParams[SAVED_RECIPES_KEY];
  if (!savedRecipesString) return [];
  return savedRecipesString.split(',').map(id => parseInt(id, 10));
}

export const setActiveRecipeQueryParam = (recipeId) => {
  const queryParamsObj = getQueryParamsObjFromUrl();
  if (recipeId) {
    queryParamsObj[ACTIVE_RECIPE_KEY] = recipeId;
  } else {
    delete queryParamsObj[ACTIVE_RECIPE_KEY];
  }
  setQueryParamsObjectToUrl(queryParamsObj);
}

export const getActiveRecipeFromUrl = () => {
  const queryParamsObj = getQueryParamsObjFromUrl();
  return queryParamsObj[ACTIVE_RECIPE_KEY];
};
