import { useEffect } from 'react';
import './RecipeDetails.scss';

const RecipeDetails = ({ handleCloseDetails, handleRemove, handleSave, isSaved, recipe }) => {
  const { id, name, image, ingredients = [], instructions = [], link } = recipe;

  useEffect(() => {
    window.scrollTo(0,0)
  }, []);

  return (
    <div className="recipe-details">
      <div className="buttons">
        <button className="back-button" onClick={handleCloseDetails}>Back</button>
        {isSaved ? (
          <button className="button" onClick={() => handleRemove(id)}>Remove</button>
        ) : (
          <button className="button" onClick={() => handleSave(id)}>Save</button>
        )}
      </div>
      <div className="details-wrapper">
        <div className="image-wrapper">
          <img className="image" src={image} alt={name} />
        </div>
        <div className="details">
          <h2 className="title">{name}</h2>
          {link ? (
            <>
              <span>{">>"}</span>
              <a className="button primary" href={link} rel="noopener noreferrer" target="_blank">External Link</a>
              <span>{"<<"}</span>
            </>
           ) : null}
          <div className="ingredients-wrapper">
            {ingredients.map((ingredient, idx) => (
              <div className="ingredient" key={idx}>{ingredient}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="instructions-wrapper">
        {instructions.map((instruction, idx) => (
          <p
            className="instruction"
            key={idx}
            dangerouslySetInnerHTML={{ __html: `${idx + 1}. ${instruction}` }}
          />
        ))}
      </div>
    </div>
  );
}

export default RecipeDetails;
