import React, { useEffect, useState } from 'react';

import RecipeList from './components/RecipeList/RecipeList';
import RecipeItem from './components/RecipeItem/RecipeItem';
import { fetchAllRecipes, fetchFilters } from './utils/api';
import { getSavedRecipesFromLocalStorage, storeSavedRecipes } from './utils/localStorage';
import {
  getActiveRecipeFromUrl,
  getSavedRecipeIdsFromUrl,
  setActiveRecipeQueryParam,
  setSavedRecipesQueryParam,
} from './utils/url';

import './App.scss';

function App() {
  const [allRecipes, setAllRecipes] = useState([]);
  const [allFilters, setAllFilters] = useState({});
  const [savedRecipes, setSavedRecipes] = useState([]);
  const hasSavedRecipes = savedRecipes.length > 0;

  useEffect(() => {
    const init = async () => {
      // fetch all recipes
      const recipesResponse = await fetchAllRecipes();
      setAllRecipes(recipesResponse);

      // fetch all filters
      const filtersResponse = await fetchFilters();
      setAllFilters(filtersResponse);

      // read saved recipes from url, if available
      const savedRecipesFromUrl = getSavedRecipeIdsFromUrl();
      if (savedRecipesFromUrl.length > 0) {
        setSavedRecipes(savedRecipesFromUrl);
      } else { // else, read saved recipes from local storage, if available
        const storedSavedRecipes = getSavedRecipesFromLocalStorage();
        if (storedSavedRecipes) setSavedRecipes(storedSavedRecipes);
      }
    };
    init();
  }, []);

  const [savedRecipesOpen, setSavedRecipesOpen] = useState(false);
  const toggleSavedRecipes = () => setSavedRecipesOpen(prev => !prev);

  useEffect(() => {
    if (savedRecipes.length === 0 && savedRecipesOpen) {
      toggleSavedRecipes();
    }
  }, [savedRecipes, savedRecipesOpen, toggleSavedRecipes]);

  const updateSavedRecipes = (newSavedRecipes) => {
    storeSavedRecipes(newSavedRecipes);
    setSavedRecipes(newSavedRecipes);
    setSavedRecipesQueryParam(newSavedRecipes);
  };

  const saveRecipe = (id) => {
    if (savedRecipes.find(savedId => savedId === id)) return;
    const newSavedRecipes = [...savedRecipes, id];
    updateSavedRecipes(newSavedRecipes);
  };

  const removeRecipe = (id) => {
    const newSavedRecipes = savedRecipes.filter(savedId => savedId !== id);
    updateSavedRecipes(newSavedRecipes);
  };

  const removeAllRecipes = () => {
    updateSavedRecipes([]);
  }

  const toggleSavedRecipesLabel = savedRecipesOpen ? 'Close' : `Meals [${savedRecipes.length}]`;

  const [selectedRecipeId, setSelectedRecipeId] = useState(() => {
    const activeRecipeId = getActiveRecipeFromUrl();
    if (activeRecipeId) {
      return parseInt(activeRecipeId, 10);
    }
    return null;
  });
  const showRecipeDetails = recipeId => {
    setSelectedRecipeId(recipeId);
    setActiveRecipeQueryParam(recipeId);
  }
  const closeRecipeDetails = () => {
    setSelectedRecipeId(null);
    setActiveRecipeQueryParam(null);
  }

  return (
    <div className="app">
      <header className="app-header">
        <h1 className="app-title">Meal Planner</h1>
        {hasSavedRecipes ? (
          <>
            <button className="saved-recipes-toggle button primary" onClick={toggleSavedRecipes}>{toggleSavedRecipesLabel}</button>
            <div className={`saved-recipes ${savedRecipesOpen ? 'open' : ''}`}>
              <div className="saved-recipes-title">Saved Meals</div>
              {savedRecipes.map(id => {
                const recipe = allRecipes.find(recipe => recipe.id === id);
                return (
                  <RecipeItem
                    key={id}
                    recipe={recipe}
                    handleRemove={removeRecipe}
                    handleShowDetails={showRecipeDetails}
                    isSaved
                  />
                );
              })}
              <button className="clear-saved-recipes button" onClick={removeAllRecipes}>Clear List</button>
            </div>
          </>
        ) : null}
      </header>
      {allRecipes.length > 0 ? (
        <div className="app-content">
          <RecipeList
            allFilters={allFilters}
            allRecipes={allRecipes}
            handleShowDetails={showRecipeDetails}
            handleCloseDetails={closeRecipeDetails}
            removeRecipe={removeRecipe}
            saveRecipe={saveRecipe}
            savedRecipes={savedRecipes}
            selectedRecipeId={selectedRecipeId}
          />
        </div>
      ) : null}
    </div>
  );
}

export default App;
